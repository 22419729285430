import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Form, ToggleButton } from "react-bootstrap";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import DragDrop from "../../Common/Drag&Drop/Drag&Drop";
import EdithDishestype from "../../Assets/img/Catalogs/EdithDishestype.svg";
import AddDishestype from "../../Assets/img/Catalogs/AddDishestype.svg";
import configData from "../../../config.json"
import "./DishGroup.css";

const DishGroup = ({ item, saveData, setNewValue, infoModel,handleShow,setCurrentRow }) => {
  const [childOpen, setChildOpen] = useState(false);
  const [listItem, setListItem] = useState([]);
  

  useEffect(() => {
    const newObj = item.Dishes.map((item, index) => {
      return {
        id: `itemId${index}`,
        content: getRow(item, index),
        rowData: item,
      };
    });
    setListItem(newObj);
  }, []);

  function getRow(dataRow, index) {
    return (
      <div className="rowDragDropDishes__container">
        <div>
        <button
            className="btnEditMenu btnRowDataTable"
            onClick={() => {
              handleShow();
              setCurrentRow(dataRow);
            }}
          >
            <img className="imgEditMenu" src={EdithDishestype} />
          </button>
        </div>
        <div className="NameDescriptionItemDish">
          <h3>{dataRow.Name}</h3>
          <h4>{dataRow.Description}</h4>
          </div>
        <div>
          <i
            className={`fa fa-check-circle-o ActiveDataTable__Menu ${
              !dataRow.Active && "ActiveDataTable__MenuInactive"
            }`}
            aria-hidden="true"
          />
        </div>
        <div className="">
         <img className="imgDishDragDrop" src={dataRow.Urlimage!=null&&dataRow.Urlimage.replace("#hostImg#",configData.SERVER_URL_IMG)}/>
        </div>
        <div className="">
         {dataRow.Price}
        </div>
      </div>
    );
  }
  const DishGroupR = (
    <>
      <div
        className="DishGroupParent__container"
        onClick={() => setChildOpen(!childOpen)}
      >
        <div className="rowDragDropCategories__container rowDragDropCategories__Description">
          <div className="statusOpenParent">{!childOpen ? "+" : "-"}</div>
          <div>{item.Name}</div>
          <div className="">{item.Order}</div>
          <div />
        </div>
      </div>
      <div
        className={`DishGroupChild__container ${
          childOpen ? "DishGroupChild__Open" : "DishGroupChild__Close"
        }`}
      >
        {listItem.length === 0 ? (
          <div className="titleNoDishes">Create dishes</div>
        ) : (
          <>
            <div className="rowDragDropDishes__container rowDragDropDishes__title">
              <div></div>
              <div>Name</div>
              <div className="">Code</div>
              <div className="">IMAGE</div>
              <div className="">PRICE</div>
              <div />
            </div>
            <DragDrop listItem={listItem} setListItem={setListItem} />
          </>
        )}
      </div>
      
    </>
  );

  return DishGroupR;
};
export default DishGroup;
