import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import "./inputForm.css";

const Profile = ({
  title,
  type,
  placeholder,
  field,
  infoModel,
  setNewValue,
  Row,
  required = false,
  as = "input",
  readDataM=null
}) => {
  const readData = readDataM
    ? readDataM(infoModel, Row.Id)
    : Row != {} && Row != null
    ? infoModel.find((x) => x.Id == Row.Id)
    : infoModel;
  return (
    <>
      <InputGroup className="mb-3 inputForm__container">
        <InputGroup.Text className="inputForm__title">{title}</InputGroup.Text>
        <FormControl
          placeholder={placeholder}
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={(input) => setNewValue(field, input.target.value)}
          value={readData != null ? readData[field] : ""}
          required={required}
          as={as}
        />
        <FormControl.Feedback type="invalid">
          Please provide a {title}.
        </FormControl.Feedback>
      </InputGroup>
    </>
  );
};
export default Profile;
