import React, { useState, useEffect, useContext } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import HeaderDashboard from "../Common/HeaderDashboard/HeaderDashboard";
import { Carousel } from "react-responsive-carousel";
import requestAxios from "../../util/requestAxios";
import AppContext from "../../Context/AppContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Dashboard.css";
const Dashboard = () => {
  const [dataCharByMonth, setDataCharByMonth] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [dataCharPerDay, setDataCharPerDay] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  useEffect(() => {
    loadInfo();
    loadInfo2();
  }, []);
  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Rate/GetMenuUseByRestaurant`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    var labels = [];
    var values = [];
    data.map(function (item, i) {
      labels.push(item.Menu);
      values.push(item.VisitCount);
    });
    const dataCharByMonth = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: values,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255,99,132,1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    setDataCharByMonth(dataCharByMonth);
    changeLoading(false);
  }
  async function loadInfo2() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Rate/GetMenuUseByRestaurantByHour`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    var labels = [];
    var values = [];
    data.map(function (item, i) {
      labels.push(item.Restaurant.Hour);
      values.push(item.VisitCount);
    });
    const dataCharPerDay = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: values,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255,99,132,1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    setDataCharPerDay(dataCharPerDay);
    changeLoading(false);
  }
  return (
    <>
      <HeaderDashboard />
      <div className="box-container">
        <div className="Dashboard__container">
          <Carousel autoPlay className="CarouselCharts__container">
            <div style={{height:"50vh",margin: "100px",marginTop:"50px"}}>
              <h1># of Visits in the month</h1>
              <Bar
                data={dataCharByMonth}
                className="chartDashboard VisitByMonth"
                width={"400px"}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "# of Visits in the month",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
            <div  style={{height:"50vh",marginTop:"50px"}}>
              <h1># of Visits per hour</h1>
              <Doughnut
                data={dataCharPerDay}
                className="chartDashboard"
                width={"400px"}
                height={"400px"}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "# of Visits in the month",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}              />
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
