import requestAxios from "../../../util/requestAxios";
const ComboBox = {
  cmbCategories: async function (infoUs,menuCode) {
    const { error, data } = await requestAxios({
        url: `DishTypes/GetCategoriesByUser?menuCode=${menuCode}`,
        method: "get",
        token: infoUs.bearer_token,
        data: {},
      });
      return {error,data}
  },
};
export default ComboBox;
