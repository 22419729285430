import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import requestAxios from "../../../util/requestAxios";
import "./Order.css";
import GoKitchenIcon from "../../Assets/img/Catalogs/cooking.svg";
import BarDrinkIcon from "../../Assets/img/Catalogs/bar-drink.svg";
import WaiterDinnerTableIcon from "../../Assets/img/Catalogs/waiter-dinner-table.svg";

const Restaurant = () => {
  const history = useHistory();
  const [infoModel, setInfoModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);


  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  
  useEffect(() => {
    loadInfo();
  }, []);

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "Restaurants/GetRestaurantList",
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    setInfoModel(data);
    changeLoading(false);
  }
 
  const columns = [
    {
      name: "Restaurant's Name",
      selector: (row) => row.Name,
    },
    {
      name: "KITCHEN ORDERS",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`Kitchen/${row.Id}/1`);
          }}
        >
          <img className="imgEditMenu" src={GoKitchenIcon} />
        </button>
      ),
    },
    {
      name: "BAR ORDERS",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`Kitchen/${row.Id}/2`);
          }}
        >
          <img className="imgEditMenu" src={BarDrinkIcon} />
        </button>
      ),
    },
    {
      name: "TABLES AVAILABLE",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`TablesOrders/${row.Id}`);
          }}
        >
          <img className="imgEditMenu" src={WaiterDinnerTableIcon} />
        </button>
      ),
    },
  ];

  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Restaurant
          
        </h1>
        <div className="Restaurant__container">
          <DataTable columns={columns} data={infoModel} />
        </div>
      </div>
    </>
  );
};
export default Restaurant;
