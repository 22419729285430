import React from "react";
import Fondo3 from "../../../../Assets/img/Home/fondo-3.png";
// import DMTYellowLogo from "../../../../Assets/img/Home/DMTYellow.png";
// import PhoneLogo from "../../../../Assets/img/Home/PHONE.png";s
import "./Section3.css";

const Home = () => {
  return (
    <>
      <div className="LoginSection3IMG__container">
        <img src={Fondo3} />
      </div>
      <div className="LoginSection3Video__container">
        <iframe
          
          src="https://www.youtube.com/embed/iTHdXlqrZFU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </>
  );
};
export default Home;
