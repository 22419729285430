import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheckCircle,faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import AppContext from "../../../Context/AppContext";
import withReactContent from "sweetalert2-react-content";
import requestAxios from "../../../util/requestAxios";
import requestSignalR from "../../../util/requestSignalR";

const TableOrderTypeOrders = ({ item, signalConnection }) => {
   
  const MySwal = withReactContent(Swal);
  const [currentType, setCurrentType] = useState(1);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  const OrdersSelected = item.CurrOrd.Receipt.Orders.filter(
    (x) => x.StatusId === currentType
  );
  const unique = [...new Set(OrdersSelected.map((item) => item.Name))];
  const OrdersShow = unique.map((item) => ({
    Name: item,
    Dishes: OrdersSelected.filter((x) => x.Name === item),
  }));

  async function cancelDish(itemx) {
    await MySwal.fire({
      title: "Cancel dish",
      text: `Do you want cancel the dish ${itemx.Dish}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        changeLoading(true);
        const { error, data } = await requestAxios({
          url: `OrderMov/CancelDishMov?OrderMovId=${itemx.Index}`,
          method: "post",
          token: infoUs.bearer_token,
          data: { Index: Number(itemx.Index) },
        });

        if (error === "") {
          requestSignalR.UpdateRecieptByTable(
            signalConnection,
            item.CurrOrd.Info.TableId
          );
        }
        changeLoading(false);
      }
    });
  }
  async function sendDish(itemx) {
     
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `OrderMov/SendDishMov?OrderMovId=${itemx.Index}`,
      method: "post",
      token: infoUs.bearer_token,
      data: {
        Index: Number(itemx.Index),
        RestaurantId: item.CurrOrd.Info.RestaurantId,
        TableId: item.CurrOrd.Info.TableId,
      },
    });
     
    if (error === "") {
      requestSignalR.UpdateRecieptByTable(
        signalConnection,
        item.CurrOrd.Info.TableId
      );
    }
    changeLoading(false);
  }
  
  return (
    <>
      <div className="TableOrderTypeOrdersbtn__container">
        <Button
          variant={currentType === 1 ? `primary` : "light"}
          onClick={() => setCurrentType(1)}
        >
          ORDERS SENT
        </Button>
        <Button
          variant={currentType === 2 ? `primary` : "light"}
          onClick={() => setCurrentType(2)}
        >
          ORDERS READY
        </Button>
        <Button
          variant={currentType === 4 ? `primary` : "light"}
          onClick={() => setCurrentType(4)}
        >
          ORDERS DELIVERED
        </Button>
      </div>

      <div className="TableOrderTypeOrdersDishes__container">
        {OrdersShow.map((itemx, i) => (
          <div>
            <h1 className="TableOrderDishInfo__CustomerName">{itemx.Name}</h1>
            {itemx.Dishes.map((itemxx, ix) => (
              <div
                className={`TableOrderDishInfo__container ${
                  (ix + 2) % 2 && "BackgroundGray"
                }`}
              >
                <div className="TableOrderDishInfo__Trash">
                  {currentType === 1 && (
                    <button onClick={() => cancelDish(itemxx)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                  {currentType === 2 && (
                    <button className="btnSendDish__TableOrders" onClick={() => sendDish(itemxx)}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  )}
                   {currentType === 4 && (
                     <div className="btnSendDish__TableOrders">
                      <FontAwesomeIcon icon={faCheckDouble} />
                      </div>
                  )}
                </div>
                <div>
                  <h1 className="TableOrderDishInfo__DishName">
                    {itemxx.Dish}
                  </h1>
                  <h1 className="TableOrderDishInfo__DishSpecialInstruction">
                    {itemxx.SpecialInstruccion !== "" && (
                      <>
                        <span>Special Instruction:</span>
                        <br />
                        {itemxx.SpecialInstruccion}
                      </>
                    )}
                  </h1>
                </div>
                <div>
                  <h1 className="TableOrderDishInfo__DishPrice">
                    $ {itemxx.DishPrice}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default TableOrderTypeOrders;
