import Login from "../Components/Common/Login/Login";
import Dashboard from "../Components/Catalogs/Dashboard";
import Profile from "../Components/Catalogs/Profile/Profile";
import Restaurant from "../Components/Catalogs/Restaurant/Restaurant";
import Menu from "../Components/Catalogs/Menu/Menu";
import Categories from "../Components/Catalogs/Categories/Categories";
import Dishes from "../Components/Catalogs/Dishes/Dishes";
import Table from "../Components/Catalogs/Table/Table";
import Employee from "../Components/Catalogs/Employee/Employee";
import Order from "../Components/Catalogs/Order/Order";
import Kitchen from "../Components/Catalogs/Kitchen/Kitchen";
import TablesOrders from "../Components/Catalogs/TablesOrders/TablesOrders";
import Users from "../Components/Catalogs/Users/Users";
import { Redirect, Route } from "react-router-dom";
import PublicMenu from "../Components/Public/Menu/Menu";
import Home from "../Components/Public/Home/Home";
import ResetPassword from "../Components/Public/ResetPassword/ResetPassword";

const routes = [
  {
    path: "/login",
    component: Login,
    isPrivate: false,
    isExact: true,
  },
  {
    path: "/Dashboard",
    component: Dashboard,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Profile",
    component: Profile,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Restaurant",
    component: Restaurant,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Menu/:id",
    component: Menu,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Categories/:id",
    component: Categories,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Dishes/:id",
    component: Dishes,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Table/:id",
    component: Table,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Employee/:id",
    component: Employee,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Order",
    component: Order,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Kitchen/:RestaurantId/:TypeId",
    component: Kitchen,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/TablesOrders/:RestaurantId",
    component: TablesOrders,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/Users",
    component: Users,
    isPrivate: true,
    isExact: true,
  },
  {
    path: "/PublicMenu/:MenuCode/:OrdenToken?",
    component: PublicMenu,
    isPrivate: false,
    isExact: false,
  },
  {
    path: "/PublicTable/:MenuCode/:TableToken?",
    component: ({ match }) => {
      window.location.replace(`./PublicMenu/${match.params.MenuCode}`);
    },
    isPrivate: false,
    isExact: false,
  },
  {
    path: "/ResetPassword/:PasswordToken",
    component: ResetPassword,
    isPrivate: false,
    isExact: false,
  },
];

export default routes;
