import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ItemComponent } from "react-simple-tree-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faPowerOff,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import enter from "../../../Assets/img/Catalogs/enter.png";

const MenuNavItem = (props) => {
  const history = useHistory();
  const [image, setImage] = useState("");

  (function (imageName) {
    if (imageName != "") {
      import(`../../../Assets/img/Catalogs/${imageName}`).then((image) =>
        setImage(image.default)
      );
    }
  })(props.imageURL);
  return (
    <div
      className={`itemMenu__Container ${
        props.active && "itemMenu__Container__active"
      }`}
      style={{ marginLeft: `${props.level * 20}px` }}
    >
      {props.imageURL == "" ? (
        <div />
      ) : (
        <img
          className="imgIconsMenu"
          src={image}
          onClick={() => props.hasNodes && props.toggleNode()}
        />
      )}
      {props.hasNodes ? (
        <button
          className="btnOpenNodeMenuNav"
          onClick={() => props.toggleNode()}
        >
          <FontAwesomeIcon icon={props.isOpen ? faMinusCircle : faPlusCircle} />
        </button>
      ) : (
        <div />
      )}
      <ItemComponent
        {...props}
        className="menuItemText__container"
        onClick={() => {
          if (props.url !== "") {
            history.push(props.url);
          }
        }}
      />
      {props.url !== "" && (
        <button
          className="btnEnter_MenuNav"
          onClick={() => history.push(props.url)}
        >
          <img className="iconEnter_MenuNav" src={enter} />
        </button>
      )}
    </div>
  );
};
export default MenuNavItem;
