import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import { Modal, InputGroup, FormControl, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../Context/AppContext";
import useScroll from "../../../hooks/useScroll";
import requestAxios from "../../../util/requestAxios";
import BackGroundLoginImg from "../../Assets/img/Home/BackGroundLogin.png";
import DMTYellowLogo from "../../Assets/img/Home/DMTYellow.png";

import "./Login.css";

const Login = ({ show, setShow }) => {
  const { login, changeLoading } = useContext(AppContext);
  const [showModalReset, setShowModalReset] = useState(false);
  const [typeLogin, setTypeLogin] = useState(1);
  const [txtEmail, setTxtEmail] = useState("");
  const [errorLogin, seterrorLogin] = useState({
    severity: 0,
    visible: 0,
    message: "",
  });
  const history = useHistory();
  const match = useRouteMatch("/login");
  const form = useRef(null);
  const { webAlias } = useParams();
  const [blockScroll, allowScroll] = useScroll();
  const MySwal = withReactContent(Swal);
  const handleLogin = async (event) => {
    changeLoading(true);
    event.preventDefault();
    const formData = new FormData(form.current);
    const email = formData.get("email");
    if (!showModalReset) {
      const password = formData.get("password");

      const userStep = await login(email, password, typeLogin);
      if (userStep === true) {
        history.push("/dashboard");
      }
    }
    else{
      const { error, data } = await requestAxios({
        url: "Users/ResetPasswordUser?email=" + email,
        method: "post",
        data: {},
      });
      if(error===""){
        MySwal.fire({
          title: "Reset sent!",
          text: "Please verify your email.",
          icon: "success",
          onfirmButtonColor: "#3085d6",
        }).then((result) => {
          window.location.replace("/Home");
        });
      }
     else{
        MySwal.fire({
          title: "Error!",
          text: "Please verify your email.",
          icon: "error",
          onfirmButtonColor: "#3085d6",
        }).then((result) => {
        });
      }
    }
    changeLoading(false);
  };
  const LoginR = (
    <>
      <Modal className="LoginModalModal__container" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <div
          className="LoginModal__container"
          style={{ backgroundImage: `url(${BackGroundLoginImg}` }}
        >
          <div className="LoginModalContent__container">
            <Modal.Header closeButton></Modal.Header>
            <form ref={form} onSubmit={handleLogin}>
              <img className="imgHeaderLogin" src={DMTYellowLogo} />
              {showModalReset&&<h1>Reset password</h1>}
              <InputGroup className="mb-3 inputForm__container">
                <InputGroup.Text className="inputForm__title">
                  Email
                </InputGroup.Text>
                <FormControl
                  placeholder={"Email"}
                  type="email"
                  name="email"
                  onChange={(input) => setTxtEmail(input.value)}
                  required
                />
                <FormControl.Feedback type="invalid">
                  Please provide a Email.
                </FormControl.Feedback>
              </InputGroup>
              {!showModalReset && (
                <InputGroup className="mb-3 inputForm__container">
                  <InputGroup.Text className="inputForm__title">
                    Password
                  </InputGroup.Text>
                  <FormControl
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                  <FormControl.Feedback type="invalid">
                    Please provide a Password.
                  </FormControl.Feedback>
                </InputGroup>
              )}
              <button className="button-empty-big gradientdark" type="submit">
                LET’S GO!
              </button>
              <br />
              <h5>Forgot Password?</h5>
              {!showModalReset ? (
                <Button
                  variant="danger"
                  onClick={() => setShowModalReset(!showModalReset)}
                >
                  Reset my Password
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={() => setShowModalReset(!showModalReset)}
                >
                  Sign in
                </Button>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
  return LoginR;
};

export default Login;
