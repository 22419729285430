import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AppContext from '../Context/AppContext';

const AppRoute = ({ component: Component, path, isPrivate, exact, ...rest }) => {
    const { checkUserSession } = useContext(AppContext);
    return isPrivate ? (
        <Route
            exact={exact}
            path={path}
            render={(props) =>
                checkUserSession().Token ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login' }} />
                )
            }
            
            {...rest}
        />
    ) : (
        <Route
            exact={exact}
            path={path}
            render={(props) => <Component {...props} />}
            {...rest}
        />
    );
};

export default AppRoute;
