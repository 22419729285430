import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookieStorage } from "./useCookieStorage";
import requestAxios from "../util/requestAxios";
import getUserInfo from "../util/getUserInfo";

const useGlobalState = () => {
  const history = useHistory();
  const [activeCount, setActiveCount] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [currentDishPublicMenu, setCurrentDishPublicMenu] = useState(null);

  const [
    userSession,
    setUserSession,
    updateCookieStorage,
    deleteCookieStorage,
  ] = useCookieStorage("userSession", {});

  // LOGIN
  const login = async (email, password, loginType) => {
    const userInfo = getUserInfo();

    const { error, data } = await requestAxios({
      url: "users/DoLogin",
      method: "post",
      data: {
        user: email,
        password,
      },
    });
    if (error) {
      return error.response;
    }
    setUserSession(data);
    return true;
  };
  const simulateUser = async (data) => {
     
    setUserSession(data);
    history.push("/Dashboard");

    return true;
  };

  // LogOut
  const logOut = () => {
    deleteCookieStorage();
    history.push("/Home");
  };
  const logOutStay = () => {
    deleteCookieStorage();
  };
  //  Check User Session
  const checkUserSession = () => {
    if (userSession.Token) {
      return userSession;
    }

    return false;
  };

  //  Update userStepId
  const updateUserStepId = async () => {
    const { error, data, status } = await requestAxios({
      url: `customer/GetCurrentUserStep/${userSession.userId}`,
      token: userSession.token,
    });
    if (error) {
      if (status === 401) {
        logOut();
      } else {
        window.alert("Error with the server, we are fixing it");
      }
    } else {
      updateCookieStorage({
        ...userSession,
        userStepId: data.userStepId,
        assessmentId: data.assessmentId,
        browser: getUserInfo().browser,
      });
    }

    return data;
  };
  //  Update userStepId
  const updatePublicProfile = async (data) => {
    updateCookieStorage({
      ...userSession,
      PublicProfile: data,
    });

    return true;
  };
  const changeLoading = (loading) => setShowLoading(loading);
  /*----------------------set current dish of public menu------------------*/
  const setDishPublicMenu = (item) => setCurrentDishPublicMenu(item);
  const getDishPublicMenu = () => currentDishPublicMenu;
  return {
    login,
    simulateUser,
    logOut,
    logOutStay,
    checkUserSession,
    updateUserStepId,
    activeCount,
    setActiveCount,
    changeLoading,
    showLoading,
    updatePublicProfile,
    setDishPublicMenu,
    getDishPublicMenu,
  };
};

export default useGlobalState;
