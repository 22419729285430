import React, { useEffect, useState, useContext } from "react";
import TableOrderModal from "./TableOrderModal";
import TableIcon from "../../Assets/img/Catalogs/TableOrder.svg";
const TableOrderCard = ({ item, signalConnection }) => {
  const [show, setShow] = useState(false);

  function getStatus() {
    let currstatus = 0;
    if (item.CurrOrd != null) {
      var DishesEnd = item.CurrOrd.Mov.filter(
        (itemx) => itemx.StatusMovId === 2
      );
      var pendingCook = item.CurrOrd.Mov.filter(
        (itemx) => itemx.StatusMovId === 1
      );
      var DishDelivered = item.CurrOrd.Mov.filter(
        (itemx) => itemx.StatusMovId > 2
      );
      if (DishesEnd.length > 0) {
        currstatus = 2;
      } else {
        if (pendingCook.length > 0) {
          currstatus = 1;
        } else {
          if (DishDelivered.length > 0) {
            currstatus = 3;
          } else {
            if (DishesEnd.length > 0) {
              currstatus = 2;
            } else {
              currstatus = 1;
            }
          }
        }
      }
    } else {
      currstatus = 0;
    }
    return currstatus;
  }
  const currStatus = getStatus();
  return (
    <>
      <div
        className={`TableOrderCard__container ${
          currStatus === 0 && "btnDisable"
        } ${currStatus === 2 && "hurryUpKitchen"}`}
        onClick={() => {
          if (currStatus !== 0) setShow(true);
        }}
      >
        <img src={TableIcon} />
        <h1 className={`TableOrderCard__TextBackground${currStatus}`}>
          {item.Table.Code}
        </h1>
        
      </div>
      {show === true && (
          <TableOrderModal
            item={item}
            show={show}
            setShow={setShow}
            signalConnection={signalConnection}
          />
        )}
    </>
  );
};
export default TableOrderCard;
