import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import "./Profile.css";

const Profile = () => {
  const [infoModel, setInfoModel] = useState({});
  const [validated, setValidated] = useState(false);

  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  useEffect(async () => {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "users/GetCurrentUser",
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    setInfoModel(data.User);
    changeLoading(false);
  }, []);

  function setNewValue(field, value) {
    const newInfoModel = {
      ...infoModel,
    };
    newInfoModel[field] = value;
    setInfoModel(newInfoModel);
    console.log(infoModel);
  }

  async function saveData() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "users",
      method: "put",
      token: infoUs.bearer_token,
      data: infoModel,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
    setInfoModel(data.User);
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };
  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>Profile</h1>
        <div className="Profile__container">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <InputForm
              title="Username"
              field="UserName"
              infoModel={infoModel}
              setNewValue={setNewValue}
              setNewValue={setNewValue}
              required
            />
            <div className="Profile__containerControls">
              <InputForm
                title="Firstname"
                field="FirstName"
                infoModel={infoModel}
                setNewValue={setNewValue}
                required
              />
              <InputForm
                title="Lastname"
                field="LastName"
                infoModel={infoModel}
                setNewValue={setNewValue}
                required
              />
            </div>
            <InputForm
              title="Comercial Name"
              field="ComercialName"
              infoModel={infoModel}
              setNewValue={setNewValue}
            />
            <div className="Profile__containerControls">
              <InputForm
                title="Age"
                field="Age"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="Country"
                field="Country"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="State"
                field="State"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="City"
                field="City"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="Address"
                field="Address"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="Email"
                field="Email"
                infoModel={infoModel}
                setNewValue={setNewValue}
                required
              />
              <InputForm
                title="Password"
                field="Password"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
              <InputForm
                title="Confirm Password"
                field="Confirmassword"
                infoModel={infoModel}
                setNewValue={setNewValue}
              />
            </div>
            <Button type="submit">SAVE</Button>
          </Form>
        </div>
      </div>
    </>
  );
};
export default Profile;
