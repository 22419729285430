import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import TableOrderCard from "./TableOrderCard";
import requestAxios from "../../../util/requestAxios";
import requestSignalR from "../../../util/requestSignalR";
import "./TablesOrders.css";

const Kitchen = () => {
  const [infoModel, setInfoModel] = useState([]);
  const [tableRestaurant, setTableRestaurant] = useState([]);
  const [signalConnection, setSignalConnection] = useState(null);
  const { RestaurantId, TypeId } = useParams();
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();

  useEffect(() => {
    setConnectionsTables();
  }, []);
  async function setConnectionsTables() {
    const newConnection = await requestSignalR.StartConnection(loadInfo);
    setSignalConnection(newConnection);
    loadInfoFirst(newConnection);
    loadInfo();
  }
  async function loadInfoFirst(newConnection) {
    const { error, data } = await requestAxios({
      url: `Table/GetTablesActiveOrders?RestaurantId=${RestaurantId}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    if (newConnection != null) {
      if (data.length > 0) {
        data.forEach((item) => {
          newConnection.invoke("AddToGroupFromSystem", String(item.Table.Id));
        });
      }
    }
  }

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Table/GetTablesActiveOrders?RestaurantId=${RestaurantId}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    if (data != null && data !== "") {
      setInfoModel(data);
    }
    changeLoading(false);
  }

  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        {/* <button onClick={()=>requestSignalR.UpdateReciept(signalConnection)}>update signañl</button> */}
        <h1>Table's Orders</h1>
        <div className="TablesOrders__container">
          {infoModel.map((item) => (
            <TableOrderCard
              key={`TableOrderCard${item.Table.Code}`}
              item={item}
              signalConnection={signalConnection}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default Kitchen;
