import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import AppContext from "./Context/AppContext";
import useGlobalState from "./hooks/useGlobalState";
import AppRoute from "./Routes/AppRoute";
import routes from "./Routes/routes";
import Home from "./Components/Public/Home/Home";
import LoadingSection from "./Components/LoadingSection/LoadingSection";
import Moment from 'react-moment';
import 'moment-timezone';
import "./App.css";
const App = () => {
  const globalState = useGlobalState();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }, []);
  const reload = () => window.location.reload();

  return (
    <div className="App">
      <AppContext.Provider value={globalState}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Home" component={Home} />
          {routes.map((route, index) => (
            <AppRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
              exact={route.isExact}
              index={index}
            />
          ))}
          <Route path="/images/*" onEnter={reload} />
        </Switch>
        <LoadingSection />
      </AppContext.Provider>
    </div>
  );
};

export default App;
