import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, ToggleButton } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import DishGroup from "./DishGroup";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import AddDishestype from "../../Assets/img/Catalogs/AddDishestype.svg";
import ComboBox from "../../Common/ComboBox/ComboBox";
import UploadImg from "../../Common/Controls/UploadImg/UploadImg";
import "./Dishes.css";

const Dishes = () => {
  const [infoModel, setInfoModel] = useState([]);
  const [infoCMBCategories, setInfoCMBCategories] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [validated, setValidated] = useState(false);
  const [listItem, setListItem] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();

  useEffect(() => {
    loadInfo();
  }, []);

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Dishes/GetDishByUser?menuCode=${id}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });

    setInfoModel(data);

    const cmbCategoriesInfo = await ComboBox.cmbCategories(infoUs, id);
    setInfoCMBCategories(cmbCategoriesInfo.data);
    changeLoading(false);
  }

  async function saveData() {
    changeLoading(true);
    currentRow.Urlimage = imgUpConfig.upload.pictures[0];
    const { error, data } = await requestAxios({
      url: `Dishes/${currentRow.Id !== 0 ? "UDish" : "CDish"}?menuCode=${id}`,
      method: currentRow.Id !== 0 ? "put" : "post",
      token: infoUs.bearer_token,
      data: currentRow ,
    });

    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }

  async function saveOrderData() {
    changeLoading(true);

    const postObj = listItem.map((item) => {
      return {
        Id: item.rowData.Id,
        newOrder: item.rowData.Order,
        menuCode: id,
      };
    });
    console.log(listItem);
    const { error, data } = await requestAxios({
      url: `DishTypes/UpdateOrderCategory?menuCode=${id}`,
      method: "put",
      token: infoUs.bearer_token,
      data: postObj,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };

  function readData(infoModel, keyId) {
    const objR = infoModel.find((x) => x.Dishes.find((xx) => xx.Id === keyId));
    const objDishR = objR.Dishes.find((xx) => xx.Id === keyId);

    return objDishR ? objDishR : {};
  }

  function setNewValue(field, value) {
    const newInfoModel = [...infoModel];
    const objR = newInfoModel.find((x) =>
      x.Dishes.find((xx) => xx.Id === currentRow.Id)
    );
    let upObj = objR.Dishes.find((x) => x.Id === currentRow.Id);
    if (upObj) {
      upObj[field] = value;
      setInfoModel(newInfoModel);
      setCurrentRow(upObj);
    } else {
      let newupObj = { ...currentRow };
      const objR = newInfoModel.find((x) => x.Id == currentRow.MenuId);
      newupObj[field] = value;
      objR.push(newupObj);
      setInfoModel(newInfoModel);
      setCurrentRow(newupObj);
    }
  }

  const inputMethods = {
    infoModel,
    Row: currentRow,
    setNewValue,
    readDataM: readData,
  };

  const [imgUpConfig, setImgUpConfig] = useState({
    upload: {
      pictures: [],
      maxFileSize: 5242880,
      imgExtension: [".jpg", ".png"],
      defaultImages: [],
    },
  });

  function handleChange(files) {
    let newimgUpConfig = { ...imgUpConfig };
    newimgUpConfig.upload.pictures[0] = files[0];
    setImgUpConfig(newimgUpConfig);
  }

  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Dishes
          <button
            className="btnEnter_MenuNav"
            onClick={() => {
              handleShow();
              setCurrentRow({ Id: 0, RestaurantId: id, Active: 1 });
            }}
          >
            <img
              className="iconEnter_MenuNav btnRowDataTable"
              src={AddDishestype}
            />
          </button>
        </h1>
        <div className="btnHeaderContainer__categories">
          <button
            className="btnSaveOrder__Categories"
            onClick={() => {
              saveOrderData();
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />
            &nbsp;&nbsp; Save Order
          </button>
        </div>
        <div className="rowDragDropCategories__container rowDragDropCategories__title">
          <div></div>
          <div>CATEGORY'S NAME</div>
          <div className="">ORDER</div>
          <div />
        </div>
        {/* {infoModel.map((item) => (
          <DishGroup
            item={item}
            saveData={saveData}
            setNewValue={setNewValue}
            infoModel={infoModel}
            handleShow={handleShow}
            setCurrentRow={setCurrentRow}
          />
        ))} */}
        <div className="Restaurant__container">
          <Modal show={show} onHide={handleClose} className="ModalEditRow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{currentRow.Name} Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="comboBox comboBoxLocation__Categories">
                  Category's Name
                  <Form.Select
                    value={currentRow.TypeId}
                    onChange={(input) =>
                      setNewValue("TypeId", input.target.value)
                    }
                  >
                    {infoCMBCategories.map((item, i) => (
                      <option value={item.Id}> {item.Name}</option>
                    ))}
                  </Form.Select>
                </div>
                <InputForm
                  {...inputMethods}
                  title="Name"
                  field="Name"
                  required
                />
                <InputForm
                  {...inputMethods}
                  title="Code"
                  field="CodeName"
                  required
                />
                <InputForm
                  {...inputMethods}
                  title="Time"
                  field="Time"
                  required
                />
                <InputForm
                  {...inputMethods}
                  title="Description"
                  field="Description"
                  as={"textarea"}
                  required
                />
                <InputForm
                  {...inputMethods}
                  title="Price"
                  field="Price"
                  required
                />
                Active
                <br />
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  variant={"outline-success"}
                  name="radio"
                  value={1}
                  checked={1 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 1)}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  name="radio"
                  value={0}
                  variant={"outline-danger"}
                  checked={0 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 0)}
                >
                  Inactive
                </ToggleButton>
                <br />
                <UploadImg
                  {...imgUpConfig.upload}
                  handleChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Dishes;
