import React, { useEffect, useState, useContext } from "react";
import Moment from 'react-moment';
import KitchenCardDish from './KitchenCardDish';

const KitchenCard = ({ item,time }) => {
 
  let newDate=new Date(item.Date); 
  const utcDate=new Date(Date.UTC(newDate.getFullYear(),newDate.getMonth(),newDate.getDate(), newDate.getHours(), (newDate.getMinutes())));
  newDate=new Date(utcDate.toString());
  return (
    <div className="KitchenCard__container">
      <div className="KitchenCardTitle__container">
        Order: {item.Invoice}
        <br />
        Table: {item.TableCode}
        <br />
        <Moment format="YYYY/MM/DD HH:mm">{newDate}</Moment>

        <br />

      </div>
      {item.OrderMov.map((itemx) => (
        <KitchenCardDish key={`OrderMovId${itemx.OrderMovId}`} itemx={itemx}/>
           
      ))}

      <div className="KitchenCardFooter__container">TAP ME TO START</div>
    </div>
  );
};
export default KitchenCard;
