import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import UploadImg from "../../Common/Controls/UploadImg/UploadImg";
import "./Users.css";
import UsersIconEdit from "../../Assets/img/Catalogs/UsersIconEdit.svg";
import UsersIconAdd from "../../Assets/img/Catalogs/UsersIconAdd.svg";
import configData from "../../../config.json";

const Users = () => {
  const history = useHistory();
  const [infoModel, setInfoModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [show, setShow] = useState(false);
  const [showSimulate, setShowSimulate] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => {setShow(false);setShowSimulate(false);}
  const handleShow = () => setShow(true);
  const { checkUserSession, changeLoading,logOutStay,simulateUser } = useContext(AppContext);
  const infoUs = checkUserSession();

  useEffect(() => {
    loadInfo();
  }, []);

  function setNewValue(field, value) {
    const newInfoModel = [...infoModel];

    let upObj = newInfoModel.find((x) => x.UserId === currentRow.UserId);
    if (upObj) {
      upObj[field] = value;
      setInfoModel(newInfoModel);
      setCurrentRow(upObj);
    } else {
      let newupObj = {};
      newupObj.Id = 0;
      newupObj[field] = value;
      newInfoModel.push(newupObj);
      setInfoModel(newInfoModel);
      setCurrentRow(newupObj);
    }
  }

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "Users/UserList",
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    console.log(data);
    setInfoModel(data);
    changeLoading(false);
  }
  async function saveData() {
    changeLoading(true);

    const { error, data } = await requestAxios({
      url: currentRow.UserId != null ? "users" : "users/CUser",
      method: currentRow.UserId != null ? "put" : "post",
      token: infoUs.bearer_token,
      data: currentRow,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
    // setInfoModel(data.User);
    loadInfo();
  }
  async function simulate() {
    changeLoading(true);

    const { error, data } = await requestAxios({
      url:   "Users/Simulate",
      method:   "post",
      token: infoUs.bearer_token,
      data: {FakeUser:currentRow.Email},
    });
    if (error === "") {
      logOutStay()
      simulateUser(data)
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
    // setInfoModel(data.User);
    loadInfo();
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };
  const columns = [
    {
      name: "Edit",
      width: "150px",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            handleShow();
            setCurrentRow(row);
          }}
        >
          <img className="imgEditMenu" src={UsersIconEdit} />
        </button>
      ),
    },
    {
      name: "Name",
      selector: (row) => `${row.FirstName} ${row.LastName}`,
    },

    {
      name: "Email",
      selector: (row) => row.Email,
    },
  ];
  function readData(infoModel, keyId) {
    const objR = infoModel.find((x) => x.UserId === currentRow.UserId);
    return objR ? objR : {};
  }
  const defaultConfigField = {
    infoModel,
    setNewValue,
    readDataM: readData,
    Row: currentRow,
  };
  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Users
          <button className="btnEnter_MenuNav">
            <img
              className="iconEnter_MenuNav"
              src={UsersIconAdd}
              onClick={() => {
                handleShow();
                setCurrentRow({ Id: 0 });
              }}
            />
          </button>
          <Button variant="light" onClick={()=>setShowSimulate(true)}>
            <FontAwesomeIcon icon={faUserSecret} />
            &nbsp;SIMULATE
          </Button>
        </h1>
        <div className="Restaurant__container">
          <DataTable columns={columns} data={infoModel} />
          <Modal
            show={show}
            onHide={handleClose}
            className="ModalEditRow"
            dialogClassName="ModalEditRowWidth"
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{currentRow.Name} Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputForm
                  {...defaultConfigField}
                  title="Username"
                  field="UserName"
                  required
                />
                <div className="Profile__containerControls">
                  <InputForm
                    {...defaultConfigField}
                    title="Firstname"
                    field="FirstName"
                    required
                  />
                  <InputForm
                    {...defaultConfigField}
                    title="Lastname"
                    field="LastName"
                    required
                  />
                </div>
                <InputForm
                  {...defaultConfigField}
                  title="Comercial Name"
                  field="ComercialName"
                />
                <div className="Profile__containerControls">
                  <InputForm {...defaultConfigField} title="Age" field="Age" />
                  <InputForm
                    {...defaultConfigField}
                    title="Country"
                    field="Country"
                  />
                  <InputForm
                    {...defaultConfigField}
                    title="State"
                    field="State"
                  />
                  <InputForm
                    {...defaultConfigField}
                    title="City"
                    field="City"
                  />
                  <InputForm
                    {...defaultConfigField}
                    title="Address"
                    field="Address"
                  />
                  <InputForm
                    {...defaultConfigField}
                    title="Email"
                    field="Email"
                    required
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={showSimulate}
            onHide={handleClose}
            className=""
            dialogClassName="ModalEditRowWidth"
          >
            <Form noValidate validated={validated}  >
              <Modal.Header closeButton>
                <Modal.Title>Simulate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputForm
                  {...defaultConfigField}
                  title="Email"
                  field="Email"
                  required
                />
               
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary"  onClick={()=>simulate()}>
                  Simulate
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Users;
