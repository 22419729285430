import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import UploadImg from "../../Common/Controls/UploadImg/UploadImg";
import "./Restaurant.css";
import MenuEditIcon from "../../Assets/img/Catalogs/EditMenu.svg";
import MenuAddIcon from "../../Assets/img/Catalogs/Menu.svg";
import AddTable from "../../Assets/img/Catalogs/AddTable.svg";
import AddWaiters from "../../Assets/img/Catalogs/Waiters.svg";
import AddRestaurant from "../../Assets/img/Catalogs/AddRestaurant.svg";
import configData from "../../../config.json";

const Restaurant = () => {
  const history = useHistory();
  const [infoModel, setInfoModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  const [imgUpConfig, setImgUpConfig] = useState({
    upload: {
      pictures: [],
      maxFileSize: 5242880,
      imgExtension: [".jpg", ".png"],
      defaultImages: [],
    },
  });
  useEffect(() => {
    loadInfo();
  }, []);

  function setNewValue(field, value) {
    const newInfoModel = [...infoModel];

    let upObj = newInfoModel.find((x) => x.Id === currentRow.Id);
    if (upObj) {
      upObj[field] = value;
      setInfoModel(newInfoModel);
      setCurrentRow(upObj);
    } else {
      let newupObj = {};
      newupObj.Id = 0;
      newupObj[field] = value;
      newInfoModel.push(newupObj);
      setInfoModel(newInfoModel);
      setCurrentRow(newupObj);
    }
  }

  function handleChange(files) {
    let newimgUpConfig = { ...imgUpConfig };
    newimgUpConfig.upload.pictures[0] = files[0];
    setImgUpConfig(newimgUpConfig);
  }
  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "Restaurants/GetRestaurantList",
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    setInfoModel(data);
    changeLoading(false);
  }
  async function saveData() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Restaurants/${currentRow.Id !== 0 ? "URestaurant" : "SRestaurant"}`,
      method: currentRow.Id !== 0 ? "put" : "post",
      token: infoUs.bearer_token,
      data: { restaurant: currentRow, image: imgUpConfig.upload.pictures[0] },
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            handleShow();
            let newimgUpConfig = { ...imgUpConfig };
            newimgUpConfig.upload.defaultImages[0] = `${
              configData.SERVER_URL_IMG
            }ImagesSaved/Restaurant/${row.Id}/logo.png?time${Math.random()}`;
            setImgUpConfig(newimgUpConfig);
            setCurrentRow(row);
          }}
        >
          <img className="imgEditMenu" src={MenuEditIcon} />
        </button>
      ),
    },
    {
      name: "Menu",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`Menu/${row.Id}`);
          }}
        >
          <img className="imgEditMenu" src={MenuAddIcon} />
        </button>
      ),
    },
    {
      name: "Tables",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`Table/${row.Id}`);
          }}
        >
          <img className="imgEditMenu" src={AddTable} />
        </button>
      ),
    },
    {
      name: "Waiters",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            history.push(`Employee/${row.Id}`);
          }}
        >
          <img className="imgEditMenu" src={AddWaiters} />
        </button>
      ),
    },
  ];

  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Restaurant
          <button className="btnEnter_MenuNav">
            <img
              className="iconEnter_MenuNav"
              src={AddRestaurant}
              onClick={() => {
                handleShow();
                let newimgUpConfig = { ...imgUpConfig };
                newimgUpConfig.upload.defaultImages[0] = ``;
                setImgUpConfig(newimgUpConfig);
                setCurrentRow({ Id: 0 });
              }}
            />
          </button>
        </h1>
        <div className="Restaurant__container">
          <DataTable columns={columns} data={infoModel} />
          <Modal show={show} onHide={handleClose} className="ModalEditRow"         dialogClassName="ModalEditRowWidth">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{currentRow.Name} Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputForm
                  title="Name's Restaurant"
                  field="Name"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                  required
                />
                <InputForm
                  title="TAX %"
                  field="Tax"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                  required
                />
                <InputForm
                  title="TIP %"
                  field="Tip"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                  required
                />
                <UploadImg
                  {...imgUpConfig.upload}
                  handleChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Restaurant;
