import React from "react";
import DMTYellowLogo from "../../../../Assets/img/Home/DMTYellow.png";
import "./Footer.css";

const Home = () => {
  return (
    <>
      <div className="HomeFooter__container">
        <div className="HomeFooterSocialNetwork__container">
          <div className="HomeFooterSocialNetwork__Text">
            Get connected with us on social networks!
          </div>
          <div className="HomeFooterSocialNetwork__Icon">
            <i className="fa fa-facebook-official" aria-hidden="true"></i>
            <i className="fa fa-twitter" aria-hidden="true"></i>
            <i className="fa fa-instagram" aria-hidden="true"></i>
            <i className="fa fa-youtube-play" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div className="HomeFooter__container2">
        <div className="HomeFooterLogo__container">
          <img src={DMTYellowLogo} />
        </div>
        <div className="HomeFooterLink__container">
          <div className="HomeFooterLink__title">USEFUL LINKS</div>
          <div className="HomeFooterLink__links">
            <br />
            Your Account
            <br />
            <br />
            Become an Affiliate
          </div>
        </div>
        <div className="HomeFooterLink__container">
          <div className="HomeFooterLink__title">CONTACT</div>
          <div className="HomeFooterLink__links">
            <br />
            <i className="fa fa-home" aria-hidden="true"></i>
            Miami,Florida, US
            <br />
            <br />
            <i className="fa fa-envelope" aria-hidden="true"></i>
            digitalmenutech@gmail.com
            <br />
            <br />
            <i className="fa fa-phone" aria-hidden="true"></i>+ 01 234 567 88
          </div>
        </div>
      </div>
      <div className="HomeFooter__container3">
        <span>© 2020 Copyright</span> Digitalmenutech.com
      </div>
    </>
  );
};
export default Home;
