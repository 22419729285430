import React, { useContext } from "react";
import AppContext from "../../../../../Context/AppContext";
import Fondo1 from "../../../../Assets/img/Home/fondo-1.png";
import DMTYellowLogo from "../../../../Assets/img/Home/DMTYellow.png";
import PhoneLogo from "../../../../Assets/img/Home/PHONE.png";
import "./Section1.css";

const Home = ({ setShow }) => {
  const { checkUserSession } = useContext(AppContext);
  const { bearer_token } = checkUserSession();
  return (
    <>
      <div
        className="LoginSection1IMG__container"
        style={{ backgroundImage: `url(${Fondo1}` }}
      >
        <div className="LoginSection1__container">
          <img className="Home_DMTYellowLogo" src={DMTYellowLogo} />
          <button
            type={setShow}
            className="Home_btnSignIn"
            onClick={() => {
              if (bearer_token == null) {
                setShow(true);
              } else {
                window.location = "/Dashboard";
              }
            }}
          >
            SIGN IN
          </button>
        </div>
        <img className="Home_PhoneLogoLogo" src={PhoneLogo}></img>
      </div>
    </>
  );
};
export default Home;
