import Step1Img from "../../../../Assets/img/Home/Step1.png";
import Step2Img from "../../../../Assets/img/Home/Step2.png";
import Step3Img from "../../../../Assets/img/Home/Step3.png";
import Step4Img from "../../../../Assets/img/Home/Step4.png";

const textData = {
  Step1: {
    title: "Creating the profile",
    image:Step1Img,
    description: (
      <>
        Register and upload your restaurant's complete menu. You will be able to
        design the menu style that best suits you, upload and modify the
        photographs of your products and add the availability of products or
        remove those that are sold out and also indicate the price and detail of
        each one of them, so your menu will always be up to date. You will only
        need to generate the QR code of your menu and print it to position it in
        strategic places of your store.
      </>
    ),
  },
  Step2: {
    title: "Access to the menu",
    image:Step2Img,
    description: (
      <>
        Register and upload your restaurant's complete menu. You will be able to
        design the menu style that best suits you, upload and modify the
        photographs of your products and add the availability of products or
        remove those that are sold out and also indicate the price and detail of
        each one of them, so your menu will always be up to date. You will only
        need to generate the QR code of your menu and print it to position it in
        strategic places of your store.
      </>
    ),
  },
  Step3: {
    title: "Order received",
    image:Step3Img,
    description: (
      <>
        In the kitchen, the customer's order will be received and displayed on
        any device with internet access in real time with the details of the
        order and its respective table. This will significantly reduce the
        margin of error and optimize the workflow, making it possible to operate
        even with less staff. The staff will be able to focus only on providing
        greater attention, bringing the order to the table and enriching the
        diners' experience.
      </>
    ),
  },
  Step4: {
    title: "Payment Method",
    image:Step4Img,
    description: (
      <>
        Once the order is completed, the customer will be able to pay his final
        bill directly from his cell phone in a fast and secure way by adding a
        payment method, even with the possibility of adding a tip and writing a
        commentary of his experience.
      </>
    ),
  },
};

export default textData;