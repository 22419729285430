import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Fondo2 from "../../../../Assets/img/Home/fondo-2.png";
import Step1Img from "../../../../Assets/img/Home/Step1.png";
import Step2Img from "../../../../Assets/img/Home/Step2.png";
import Step3Img from "../../../../Assets/img/Home/Step3.png";
import Step4Img from "../../../../Assets/img/Home/Step4.png";
import BackGroundLoginImg from "../../../../Assets/img/Home/BackGroundLogin.png";
import infoSteps from "./infoSteps";
import "./Section2.css";

const Home = () => {
  const [show, setShow] = useState(false);
  const [currStepInfo, setCurrStepInfo] = useState({});

  function showModal(step) {
    setShow(true);
    setCurrStepInfo(infoSteps[`Step${step}`]);
  }
  return (
    <>
      <div className="LoginSection2__container">
        <img src={Fondo2} />
        <div className="LoginSection2__btncontainer">
          <button
            type="button"
            className="LoginSection2__btnStep1"
            onClick={() => showModal(1)}
          >
            <img src={Step1Img} />
            <div className="LoginSection2__btnStepText">
              <h1 className="textYellow">Step 1</h1>
              Creating the profile
            </div>
          </button>
          <button
            className="LoginSection2__btnStep1"
            onClick={() => showModal(2)}
          >
            <img src={Step3Img} />
            <div className="LoginSection2__btnStepText">
              <h1 className="textBlue">Step 2</h1>
              Acces to the menu
            </div>
          </button>
          <button
            className="LoginSection2__btnStep1"
            onClick={() => showModal(3)}
          >
            <img src={Step3Img} />
            <div className="LoginSection2__btnStepText">
              <h1 className="textYellow">Step 3</h1>
              Order received
            </div>
          </button>
          <button
            className="LoginSection2__btnStep1"
            onClick={() => showModal(4)}
          >
            <img src={Step4Img} />
            <div className="LoginSection2__btnStepText">
              <h1 className="textBlue">Step 4</h1>
              Payment Method
            </div>
          </button>
        </div>
      </div>
      <Modal className="Section2ModalModal__container" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <div
          className="LoginModal__container"
          style={{ backgroundImage: `url(${BackGroundLoginImg}` }}
        >
          <div className="SectionModalContent__container">
            <Modal.Header closeButton>
            </Modal.Header>
            <img src={currStepInfo.image} className="imgStepLogin" />
            <h1>{currStepInfo.title}</h1>
            <h2>{currStepInfo.description}</h2>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Home;
