import React from "react";
import ImageUploader from "react-images-upload";
import "./UploadImg.css";

const UploadImg = (props) => {
  function onDrop(pictureFiles, pictureDataURLs) {
    const newImagesUploaded = pictureDataURLs ;
    props.handleChange(newImagesUploaded);
  }

  return (
    <ImageUploader
      withIcon={false}
      withLabel={false}
      withPreview={true}
      buttonText={"Add photos"}
      fileSizeError={"File size is too big!"}
      fileTypeError={"This extension is not supported!"}
      onChange={onDrop}
      imgExtension={props.imgExtension}
      maxFileSize={props.maxFileSize}
      defaultImages={props.defaultImages}
      singleImage
      onError={()=>alert("error")}
    />
  );
};

export default UploadImg;
