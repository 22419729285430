import React from "react";
import KitchenCardDishTimer from "./KitchenCardDishTimer";

const KitchenCardDish = ({ itemx }) => {
  
  let newDate=new Date(itemx.Date); 
  const utcDate=new Date(Date.UTC(newDate.getFullYear(),newDate.getMonth(),newDate.getDate(), newDate.getHours(), (newDate.getMinutes()+itemx.Time)));
  newDate=new Date(utcDate.toString()); 

   return (
    <KitchenCardDishTimer itemx={itemx} expiryTimestamp={newDate} />
   
  );
};
export default KitchenCardDish;
