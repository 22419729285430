import React, { useContext, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import useScroll from "../../hooks/useScroll";
import { Spinner } from "react-bootstrap";
import "./LoadingSection.css";
import DMTLogo from "../../Components/Assets/img/DMT.png";

const LoadingSection = () => {
  const { showLoading, checkUserSession } = useContext(AppContext);
  const [blockScroll, allowScroll] = useScroll();
  useEffect(() => {
    if (showLoading) blockScroll();
    else allowScroll();
  }, [showLoading]);

  return showLoading ? (
    <section className="loadingSection">
      <div className="loadingSection__body">
        {/* <img src={DMTLogo} alt="Oola Logo" /> */}
        <div className="spinner__container">
          <Spinner animation="grow" variant="primary" />
          <Spinner animation="grow" variant="secondary" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="light" />
          <Spinner animation="grow" variant="dark" />
        </div>
        <p className="paragraph-3 LoadingAnimation">Loading...</p>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default LoadingSection;
