import React, { useState, useEffect, useContext } from "react";
import TreeMenu from "react-simple-tree-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faPowerOff,faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../../Context/AppContext";
import { pushRotate as Menu } from "react-burger-menu";
import { useHistory } from "react-router-dom";
import requestAxios from "../../../../util/requestAxios";
import MenuNavItem from "./MenuNavItem";
import "react-simple-tree-menu/dist/main.css";
import "./MenuNav.css";

const MenuNav = () => {
  const [treeData, setNodeList] = useState([]);
  const { checkUserSession, logOut } = useContext(AppContext);
  useEffect(async () => {
    const infoUs = checkUserSession();
    const { error, data } = await requestAxios({
      url: "users/SchemaMenu",
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    let menuObj = [
      {
        key: "Home",
        label: <div className="parentNoNodes">Home</div>,
        imageURL: "homeMenu.svg",
        url: "/Dashboard",
        itemId: "",
      },
      {
        key: "InfoUser",
        label: <div className="parentNoNodes">Edit Profile</div>,
        imageURL: "man.svg",
        url: "/Profile",
        itemId: "",
      },
      {
        key: "RestaurantParent",
        label: "Restaurant",
        url: "/Restaurant",
        imageURL: "Restaurant.svg",
        nodes: data,
      },
      // {

      {
        key: "OrdersParent",
        label: "Orders Service",
        url: "/Order",
        imageURL: "BellOrders.svg",
        nodes: data.map((item, index) => ({
          key: "OrderService" + index,
          label: item.label,
          url: ``,
          imageURL: "",
          nodes: [
            {
              key: `TablesOrders${item.index}${index}`,
              label: "Kitchen Orders",
              url: `/Kitchen/${item.itemId}/1`,
              imageURL: "",
            },
            {
              key: `BarOrders${item.index}${index}`,
              label: "Bar Orders",
              url: `/Kitchen/${item.itemId}/2`,
              imageURL: "",
            },
            {
              key: `TableOrders${item.index}${index}`,
              label: "TABLES AVAILABLE",
              url: `/TablesOrders/${item.itemId}`,
              imageURL: "",
            }
          ],
          
        })),
      },
     
    ];
    if (infoUs.ProfileId === -1) {
      menuObj.push({
        key: "UsersSection",
        label: <div className="parentNoNodes">Users</div>,
        url: "/Users",
        imageURL: "UsersIcon.svg",
      });
    }
    menuObj.push( {
      key: `logout00`,
      label: (

        <button className="btnLogout" onClick={() => logOut()}>
           <FontAwesomeIcon icon={faPowerOff} /> Logout
        </button>
      ),
      url: ``,
      imageURL: "",
    });
    setNodeList(menuObj);
  }, []);

  return (
    <div className="MenuNav__container">
      <Menu width={"280px"} pushRotate>
        <TreeMenu data={treeData} hasSearch>
          {({ search, items }) => (
            <ul>
              {items.map(({ key, ...props }) => (
                <MenuNavItem key={key} {...props} />
              ))}
            </ul>
          )}
        </TreeMenu>
      </Menu>
    </div>
  );
};
export default MenuNav;
