import React,{useState} from "react";
import Login from "../../Common/Login/Login"
import Section1 from "./Section/Section1/Section1"
import Section2 from "./Section/Section2/Section2"
import Section3 from "./Section/Section3/Section3"
import FooterHome from "./Section/Footer/Footer"
import backgroundImg from "../../Assets/img/Home/backgroundHome.png"
import "./Home.css";

const Home = () => {
  const [show,setShow]=useState(false)
  return (
    <div style={{backgroundImage:`url(${backgroundImg})`, backgroundSize:"cover"}}>
     <Login show={show} setShow={setShow}/>
     <Section1 setShow={setShow}/>
     <Section2/>
     <Section3/>
     <FooterHome/>
    </div>
  );
};
export default Home;
