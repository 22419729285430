import React, { useEffect, useState, useContext, Suspense } from "react";
import { useHistory, useParams } from "react-router-dom";
import Receipt from "./Receipt/Receipt";
import AppContext from "../../../Context/AppContext";
import requestAxios from "../../../util/requestAxios";
import requestSignalR from "../../../util/requestSignalR";
import "./Menu.css";

const Menu = () => {
  const { MenuCode, OrdenToken } = useParams();
  const { changeLoading, getDishPublicMenu } = useContext(AppContext);
  const [infoModel, setInfoModel] = useState([]);
  const [DynamicComponent, setDynamicComponent] = useState(null);

  useEffect(() => {
    loadInfo();
    // loadTicket();
  }, []);
  async function loadTicket() {
    await requestSignalR();
  }

  async function createOrder(token) {
     
    changeLoading(true);
    const currDish = getDishPublicMenu();
    console.log(currDish);
    const currUserLocalS = JSON.parse(
      window.localStorage.getItem("CurrentPublicUser")
    );

    const objPost = {
      CodeURL: MenuCode,
      OrderToken: OrdenToken,
      TableKey: "",
      DishId: currDish != null ? Number(currDish.Id) : 0,
      Comment: currDish != null ? currDish.SpecialInstruction : "",
      CustomerName:currUserLocalS.CustomerName
      // RestaurantId: infoModel[0].Menu.Restaurant.Id
    };
    const { error, data } = await requestAxios({
      url: `Order/CreatePublicOrder`,
      method: "post",
      data: objPost,
    });
    if (error === "") {
    }
    changeLoading(false);
  }

  async function loadInfo() {
    changeLoading(true);

    const { error, data } = await requestAxios({
      url: `Dishes/GetDishesAndTypesPublic/${MenuCode}`,
      method: "get",
      data: {},
    });
    setInfoModel(data);
    if (data.length > 0) {
      const url = `./MenuType/Menu${data[0].Menu.MenuStyle}/Menu${data[0].Menu.MenuStyle}.jsx`;
      const Component = React.lazy(() =>
        import(
          `./MenuType/Menu${data[0].Menu.MenuStyle}/Menu${data[0].Menu.MenuStyle}.jsx`
        )
      );
      setDynamicComponent(Component);
    }
    changeLoading(false);
  }

  return (
    <>
      {OrdenToken != null && <Receipt />}
      {infoModel.length > 0 && (
        <Suspense fallback={<div>Loading...</div>}>
          {DynamicComponent && (
            <DynamicComponent infoModel={infoModel} createOrder={createOrder} />
          )}
        </Suspense>
      )}
    </>
  );
};
export default Menu;
