import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import { Modal, InputGroup, FormControl, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BackGroundLoginImg from "../../Assets/img/Home/BackGroundLogin.png";
import AppContext from "../../../Context/AppContext";
import requestAxios from "../../../util/requestAxios";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import "./ResetPassword.css";

const Home = () => {
  const [userInfo, setUserInfo] = useState("");
  const [passwords, setPasswords] = useState({});
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const { PasswordToken } = useParams();
  const MySwal = withReactContent(Swal);

  useEffect(async () => {
    const infoUs = checkUserSession();
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: "Users/GetUserByTokenPassword?token=" + PasswordToken,
      method: "get",
      data: {},
    });
    setUserInfo(data);
    changeLoading(false);
  }, []);

  async function setPassword() {
    changeLoading(true);
    const pass = passwords.Password;
    const pass2 = passwords.RPassword;
    if (pass == pass2) {
      const objPost = {
        token: PasswordToken,
        password: pass,
      };
      const { error, data } = await requestAxios({
        url: "Users/UpdatePassword?token=" + PasswordToken,
        method: "post",
        data: objPost,
      });
      if (error === "") {
        //alert("Bienvenido " + respuesta.user.name)
        MySwal.fire({
          title: "Congratulation!",
          text: "Welcome to Digital Menu Tech, please login in the home page.",
          icon: "success",
          onfirmButtonColor: "#3085d6",
        }).then((result) => {
          window.location.replace("/Home");
        });
      } else {
        MySwal.fire({
          title: "Error",
          text: "Please restart the process",
          icon: "error",
          onfirmButtonColor: "#3085d6",
        }).then((result) => {});
      }
    } else {
      MySwal.fire({
        title: "Error",
        text: "The passwords doesn't match",
        icon: "error",
        onfirmButtonColor: "#3085d6",
      }).then((result) => {});
    }
    changeLoading(false);
  }
  return (
    <>
      <div
        className="ResetPassword__container"
        style={{ backgroundImage: `url(${BackGroundLoginImg}` }}
      >
        <form className="ResetPasswordFields__container">
          <h1>Welcome {`${userInfo.FirstName} ${userInfo.LastName}`}!</h1>
          <h2>Please create a secure password</h2>
          <br />
          <InputGroup className="mb-3 inputForm__container">
            <FontAwesomeIcon icon={faKey} />
            &nbsp;&nbsp;
            <FormControl
              placeholder={"Password"}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={(input) =>
                setPasswords({...passwords, Password: input.target.value })
              }
              value={passwords.Password}
              required
              type="password"
            />
            <FormControl.Feedback type="invalid">
              Please provide a Password.
            </FormControl.Feedback>
          </InputGroup>
          <InputGroup className="mb-3 inputForm__container">
            <FontAwesomeIcon icon={faKey} />
            &nbsp;&nbsp;
            <FormControl
              placeholder={"Repeat password"}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={(input) =>
                setPasswords({...passwords, RPassword: input.target.value })
              }
              value={passwords.RPassword}
              required
              type="password"
            />
            <FormControl.Feedback type="invalid">
              Please provide a Password.
            </FormControl.Feedback>
          </InputGroup>
          <br />
          <Button
            className="btnSetPassword"
            variant="warning"
            onClick={() => setPassword()}
          >
            &nbsp;SET PASSWORD
          </Button>
        </form>
      </div>
    </>
  );
};
export default Home;
