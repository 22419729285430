import React, { useContext } from "react";
import MenuNav from "./MenuNav/MenuNav";

import AppContext from "../../../Context/AppContext";
import "./HeaderDashboard.css";

const HeaderDashboard = () => {
  const { checkUserSession, updateUserStepId } = useContext(AppContext);
  const infoUser = checkUserSession();
  return (
    <div className="HeaderDashboard__container">
      <div className="HeaderDashboard__Name">
        {` Hi! ${infoUser.FirstName} ${infoUser.LastName}`}<br/>
        <span className="HeaderDashboard__Email">({infoUser.Email})</span>
      </div>
     
      <MenuNav />
  
    </div>
  );
};
export default HeaderDashboard;
