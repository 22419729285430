import React, { useEffect, useState, useContext } from "react";
import { useParams, Route } from "react-router-dom";
import { Modal, Button, Form, ToggleButton } from "react-bootstrap";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import CreateTableIcon from "../../Assets/img/Catalogs/CreateTable.svg";
import Categories from "../../Assets/img/Catalogs/Categories.svg";
import Dishes from "../../Assets/img/Catalogs/Dishes.svg";
import EditTableIcon from "../../Assets/img/Catalogs/EditTable.svg";
import DMTQR from "../../Assets/img/Catalogs/DMTQR.png";
import "./Table.css";

const Table = () => {
  const history = useHistory();
  const [infoModel, setInfoModel] = useState({});
  const [cmbMenuInfo, setcmbMenuInfo] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();

  useEffect(() => {
    loadInfo();
    loadCMBMenu();
  }, []);

  function setNewValue(field, value) {
    const newInfoModel = [...infoModel];
    let upObj = newInfoModel.find((x) => x.Id === currentRow.Id);
    if (upObj) {
      upObj[field] = value;
      setInfoModel(newInfoModel);
      setCurrentRow(upObj);
    } else {
      let newupObj = { ...currentRow };
      newupObj[field] = value;
      newInfoModel.push(newupObj);
      setInfoModel(newInfoModel);
      setCurrentRow(newupObj);
    }
  }

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Table/GetTableDishByUser/${id}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    setInfoModel(data);
    changeLoading(false);
  }
  async function loadCMBMenu() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Menus/GetMenuByUser/${id}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    setcmbMenuInfo(data);
    changeLoading(false);
  }
  async function saveData() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Table/${currentRow.Id !== 0 ? "UTable" : "CTable"}`,
      method: currentRow.Id !== 0 ? "put" : "post",
      token: infoUs.bearer_token,
      data: currentRow,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };
  const columns = [
    {
      name: "Edit",
      width: "100px",
      selector: (row) => (
        <button
          className="btnEditMenu btnRowDataTable"
          onClick={() => {
            handleShow();
            setCurrentRow(row);
          }}
        >
          <img className="imgEditMenu" src={EditTableIcon} />
        </button>
      ),
    },
    {
      name: "Menu",
      selector: (row) => row.Menu.Name,
    },
    {
      name: "Code",
      selector: (row) => (
        <div className="textWrap" tooltip={row.Code}>
          {row.Code}
        </div>
      ),
    },
    {
      name: "# OF SEATS",
      selector: (row) => <div className="textWrap">{row.ClientQty}</div>,
    },
    {
      name: "Active",
      width: "100px",
      selector: (row) => (
        <i
          class={`fa fa-check-circle-o ActiveDataTable__Menu ${
            !row.Active && "ActiveDataTable__MenuInactive"
          }`}
          aria-hidden="true"
        ></i>
      ),
    },
  ];
  function getURLMenu(item) {
    if (item) {
      const getUrl = window.location;
      const url = getUrl.protocol + "//" + getUrl.host;
      return `${url}/PublicTable/${item.Menu.CodeUrl}/${item.PublicKey}`;
    }
  }
  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Table
          <button className="btnEnter_MenuNav">
            <img
              className="iconEnter_MenuNav"
              src={CreateTableIcon}
              onClick={() => {
                handleShow();
                setCurrentRow({
                  Id: 0,
                  RestaurantId: id,
                  Active: 1,
                  Menu: { Id: 0 },
                });
              }}
            />
          </button>
        </h1>
        <div className="Table__container">
          <DataTable columns={columns} data={infoModel} />
          <Modal show={show} onHide={handleClose} className="ModalEditRow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{currentRow.Name} Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="comboBox">
                  Menu
                  <Form.Select
                    value={currentRow.MenuId}
                    onChange={(input) =>
                      setNewValue("MenuId", input.target.value)
                    }
                  >
                    {cmbMenuInfo.map((item) => (
                      <option value={item.Id} selected="">
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <br />
                <InputForm
                  title="Code"
                  field="Code"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                  required
                />
                <InputForm
                  title="NUMBER OF CLIENTS"
                  field="ClientQty"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                />
                Active
                <br />
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  variant={"outline-success"}
                  name="radio"
                  value={1}
                  checked={1 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 1)}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  name="radio"
                  value={0}
                  variant={"outline-danger"}
                  checked={0 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 0)}
                >
                  Inactive
                </ToggleButton>
                <br />
                {currentRow.PublicKey && (
                  <div className="QRContainer__Menu">
                    <button
                      className="btnEditMenu "
                      type="button"
                      onClick={() =>
                        window.open(getURLMenu(currentRow), "_blank")
                      }
                    >
                      <QRCode
                        value={getURLMenu(currentRow)}
                        logoImage={DMTQR}
                        eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
                        logoWidth="50"
                        fgColor="gray"
                        size="250"
                      />
                    </button>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Table;
