import React, { useEffect, useState, useContext, Suspense } from "react";
import { pushRotate as Menu } from "react-burger-menu";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../../Context/AppContext";
import requestAxios from "../../../../util/requestAxios";
import requestSignalR from "../../../../util/requestSignalR";
import "./Receipt.css";
import "react-simple-tree-menu/dist/main.css";

const Receipt = () => {
  const { MenuCode, OrdenToken } = useParams();
  const { changeLoading } = useContext(AppContext);
  const [infoModel, setInfoModel] = useState(null);
  const [showRecipt, setShowRecipt] = useState(false);
  const [signalConnection, setSignalConnection] = useState(null);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    loadInfoFirstTime();
  }, []);
  async function loadInfoFirstTime() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Order/GetOrderByTable?OrderToken=${OrdenToken}`,
      method: "get",
      data: {},
    });
    const newConnection = await requestSignalR.StartConnection(loadInfo);
    newConnection.invoke("AddToGroupFromSystemByTableId", String(data.Order.Table.Id));
    setSignalConnection(newConnection);
    changeLoading(false);
  }
  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `Order/GetOrderByTable?OrderToken=${OrdenToken}`,
      method: "get",
      data: {},
    });
    setInfoModel(data);
    changeLoading(false);
    setShowRecipt(true);
  }
  function sumCustomerName(dishes, prop) {
    const sum = dishes.reduce(function (prev, cur) {
      return prev + cur[prop];
    }, 0);
    return sum;
  }
  function sumCustomerTotalName(dishes) {
    const sum = dishes.reduce(function (prev, cur) {
      return prev + (cur.DishPrice + cur.tax + cur.tip);
    }, 0);
    return sum;
  }
  function sumTotalOrder(order, prop) {
    const sum = order.reduce(
      (prev, curr) =>
        prev + curr.Dishes.reduce((prevx, currx) => prevx + currx[prop], 0),
      0
    );
    return sum;
  }
  function sumTotalOrderTotal(order) {
    const sum = order.reduce(
      (prev, curr) =>
        prev +
        curr.Dishes.reduce(
          (prevx, curx) => prevx + (curx.DishPrice + curx.tax + curx.tip),
          0
        ),
      0
    );
    return sum;
  }
  function cancelMov(index) {
    MySwal.fire({
      title: "Cancel dish!",
      text: "Do you want cancel this dish?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        changeLoading(true);
        const { error, data } = await requestAxios({
          url: `OrderMov/CancelDishMov?OrderToken=${OrdenToken}`,
          method: "post",
          data: { Index: Number(index) },
        });
        if (error === "") {
          requestSignalR.UpdateRecieptByTable(signalConnection,  String(infoModel.Order.Table.Id));
        }
        changeLoading(false);
      }
    });
  }

  function sendOrder() {
    MySwal.fire({
      title: "Is the order complete?",
      text: "Check you order",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        const { error, data } = await requestAxios({
          url: `Order/SendOrderByTable?OrderToken=${OrdenToken}`,
          method: "post",
          data: {},
        });
        if (error === "") {
          requestSignalR.UpdateRecieptByTable(signalConnection, String(infoModel.Order.Table.Id));
          changeLoading(false);

          Swal.fire({
            title: "The order has been sent!",
            text: "Please wait for it!",
            icon: "success",
            confirmButtonColor: "#3085d6",
          }).then((result) => {});
        }
      }
    });
  }
  return (
    <>
      <div className="btnRecipt__container">
        <button
          id="btnRecipt"
          type="button"
          className="btn btn-danger text-center btn-floating animated heartBeat infinite"
          // onClick="orderFunc.showRecipt()"
          onClick={() => loadInfo()}
        >
          <i className="fa fa-credit-card fa-2x"></i>
        </button>
      </div>
      <Menu
        width={"30vw"}
        pushRotate
        isOpen={showRecipt}
        className="MenuNavPublicMenu"
        burgerButtonClassName="btnMenuNavPublicMenu"
        onClose={() => setShowRecipt(!showRecipt)}
      >
        {infoModel != null && (
          <>
            <div className="menu-item" className="NoOrderReceipt">
              No Order - {infoModel.Order.Invoice}
            </div>
            <div className="menu-item" className="TableReceipt">
              Table - {infoModel.Order.Table.Code}
            </div>
            {infoModel.CustomerNames.map((item, index) => (
              <div
                key={`customerNameItemKey${item.index}`}
                className="menu-item"
              >
                <h1 className="CustomerNameReceipt">{item.Name}</h1>
                {item.Dishes.map((itemx) => (
                  <div className="ReceiptDishItem__container">
                    {itemx.Status === "Created" ? (
                      <i
                        className="fa fa-times-circle-o ReceiptDishItem__cancelIcon"
                        aria-hidden="true"
                        onClick={() => cancelMov(itemx.num)}
                      />
                    ) : (
                      <div />
                    )}
                    <h2 className="ReceiptDishItem__text">
                      {itemx.Dish}
                      <br />
                      <span>{itemx.SpecialInstruccion}</span>
                    </h2>
                    <h2 className="ReceiptDishItem__price">
                      $ {itemx.DishPrice}
                    </h2>
                  </div>
                ))}
                <div className="TableCustomeReceiptTotals">
                  <div className="menu-item" className="TableReceiptSubtotal">
                    Subtotal ${" "}
                    {Number(sumCustomerName(item.Dishes, "DishPrice")).toFixed(
                      2
                    )}
                  </div>
                  <div className="menu-item">
                    Tax ${" "}
                    {Number(sumCustomerName(item.Dishes, "tax")).toFixed(2)}
                  </div>
                  <div className="menu-item">
                    Tip ${" "}
                    {Number(sumCustomerName(item.Dishes, "tip")).toFixed(2)}
                  </div>
                  <div className="menu-item" className="TableReceiptSubtotal">
                    Total ${" "}
                    {Number(sumCustomerTotalName(item.Dishes)).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            <br />
            <span className="titleTotalOrderReceipt"> Total Order</span>
            <div className="TableReceiptTotals">
              <div className="menu-item" className="TableReceiptSubtotal">
                Subtotal ${" "}
                {Number(
                  sumTotalOrder(infoModel.CustomerNames, "DishPrice")
                ).toFixed(2)}
              </div>
              <div className="menu-item">
                Tax ${" "}
                {Number(sumTotalOrder(infoModel.CustomerNames, "tax")).toFixed(
                  2
                )}
              </div>
              <div className="menu-item">
                Tip ${" "}
                {Number(sumTotalOrder(infoModel.CustomerNames, "tip")).toFixed(
                  2
                )}
              </div>
              <div className="menu-item" className="TableReceiptSubtotal">
                Total ${" "}
                {Number(sumTotalOrderTotal(infoModel.CustomerNames)).toFixed(2)}
              </div>
            </div>
            {infoModel.CustomerNames.filter(
              (x) => x.Dishes.find((xx) => xx.Status === "Created") != null
            ).length > 0 && (
              <button
                className="btnPublicMenuSendDishes"
                onClick={() => sendOrder()}
              >
                Send Dishes
              </button>
            )}
          </>
        )}
      </Menu>
    </>
  );
};
export default Receipt;
