import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../Context/AppContext";
import requestAxios from "../../../util/requestAxios";
function MyTimer({ itemx, expiryTimestamp }) {
  const [cardVisible, setCardVisible] = useState(true);
  let dishDate = new Date(itemx.Date);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();

  const utcDate = new Date(
    Date.UTC(
      dishDate.getFullYear(),
      dishDate.getMonth(),
      dishDate.getDate(),
      dishDate.getHours(),
      dishDate.getMinutes()
    )
  );
  dishDate = new Date(utcDate.toString());
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });
  function getStatusDish(minutes) {
    if (minutes <= 0) {
      return "KitchenCardDish__containerDanger";
    } else if (minutes < 5) {
      return "KitchenCardDish__containerWarningTime";
    } else {
      return "KitchenCardDish__containerInTime";
    }
  }

  async function endCook() {
    const MySwal = withReactContent(Swal);
    await MySwal.fire({
      title: (
        <>
          Is the dish <b>{itemx.DishName}</b> complete?
        </>
      ),
      text: "Check it",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        setCardVisible(false);

        const { error, data } = await requestAxios({
          url: `OrderMov/EndCookingOrderMov?OrderMovId=${itemx.OrderMovId}`,
          method: "post",
          token: infoUs.bearer_token,
          data: {},
        });
        if (error !== "") {
          setCardVisible(true);
        }
      }
    });
  }
  return (
    <div
      className={` timer__container ${!cardVisible && "CardHidden"}`}
      onClick={() => endCook()}
    >
      <div
        key={`OrderMovId${itemx.OrderMovId}`}
        className={`KitchenCardDish__container ${getStatusDish(minutes)}`}
      >
        Dish
        <br />
        {itemx.DishName}
        <br />
        <Moment format="YYYY/MM/DD HH:mm">{dishDate}</Moment>
        {itemx.SpecialInstruccion !== "" && (
          <div className="KitchenCardDishSpecialInstructions">
            <h1>SPECIAL INSTRUCTION:</h1>
            <h3>{itemx.SpecialInstruccion}</h3>
          </div>
        )}
        <div className="KitchenCardDishTime__container">
          TIME LEFT
          <br />
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
      </div>
    </div>
  );
}
export default MyTimer;
