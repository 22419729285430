import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../Context/AppContext";
import TableOrderTypeOrders from "./TableOrderTypeOrders";
import requestAxios from "../../../util/requestAxios";
import requestSignalR from "../../../util/requestSignalR";

const TableOrderModal = ({ item, show, setShow, signalConnection }) => {
  const MySwal = withReactContent(Swal);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();
  
  async function cancelOrder(itemx) {
    await MySwal.fire({
      title: `Order ${item.CurrOrd.Receipt.Order.Invoice} will cancel !`,
      text: "Please, verify payment",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        changeLoading(true);
        const { error, data } = await requestAxios({
          url: `Order/CancelOrder?OrderId=${item.CurrOrd.Receipt.Order.Id}`,
          method: "post",
          token: infoUs.bearer_token,
          data: { Index: Number(itemx.Index) },
        });

        if (error === "") {
          setShow(false)
          requestSignalR.UpdateRecieptByTable(
            signalConnection,
            item.CurrOrd.Info.TableId
          );
        }
        changeLoading(false);
      }
    });
    changeLoading(false);
  }
  async function closeOrder(itemx) {
    await MySwal.fire({
      title: `Order ${item.CurrOrd.Receipt.Order.Invoice} will Close !`,
      text: "Please, verify payment",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        changeLoading(true);
          setShow(false)
          const { error, data } = await requestAxios({
          url: `Order/CancelOrder?OrderId=${item.CurrOrd.Receipt.Order.Id}`,
          method: "post",
          token: infoUs.bearer_token,
          data: { Index: Number(itemx.Index) },
        });

        if (error === "") {
          requestSignalR.UpdateRecieptByTable(
            signalConnection,
            item.CurrOrd.Info.TableId
          );
        }
        changeLoading(false);
      }
    });
    changeLoading(false);
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => { setShow(false)}}
        dialogClassName="modal-90w TableOrderModal__container"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title className="TableOrderCardTitleModal__container">
            ORDER: {item.CurrOrd.Info.Invoice}
            <span className="TableOrderCardTitleTableModal">
              (TABLE: {item.Table.Code})
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableOrderTypeOrders
            item={item}
            signalConnection={signalConnection}
          />
        </Modal.Body>
        <Modal.Footer className="TableOrderCardFooterModal__container">
          <FontAwesomeIcon icon="coffee" />
          <Button variant="success" onClick={()=>closeOrder(item)} >
            <FontAwesomeIcon icon={faCheckDouble} />
            &nbsp;CLOSE ORDER
          </Button>
          <Button variant="danger" onClick={()=>cancelOrder(item)}>
            <FontAwesomeIcon icon={faTrash} />
            &nbsp;CANCEL ORDER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TableOrderModal;
