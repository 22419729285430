import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, ToggleButton } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AppContext from "../../../Context/AppContext";
import HeaderDashboard from "../../Common/HeaderDashboard/HeaderDashboard";
import InputForm from "../../Common/Controls/InputForm/InputForm";
import requestAxios from "../../../util/requestAxios";
import DragDrop from "../../Common/Drag&Drop/Drag&Drop";
import EdithDishestype from "../../Assets/img/Catalogs/EdithDishestype.svg";
import AddDishestype from "../../Assets/img/Catalogs/AddDishestype.svg";

import "./Categories.css";

const Categories = () => {
  const [infoModel, setInfoModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [validated, setValidated] = useState(false);
  const [listItem, setListItem] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { checkUserSession, changeLoading } = useContext(AppContext);
  const infoUs = checkUserSession();

  useEffect(() => {
    loadInfo();
  }, []);

  function setNewValue(field, value) {
    const newInfoModel = [...infoModel];

    let upObj = newInfoModel.find((x) => x.Id === currentRow.Id);
    if (upObj) {
      upObj[field] = value;
      setInfoModel(newInfoModel);
      setCurrentRow(upObj);
    } else {
      let newupObj = { ...currentRow };
      newupObj[field] = value;
      newInfoModel.push(newupObj);
      setInfoModel(newInfoModel);
      setCurrentRow(newupObj);
    }
  }

  async function loadInfo() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `DishTypes/GetCategoriesByUser?menuCode=${id}`,
      method: "get",
      token: infoUs.bearer_token,
      data: {},
    });
    const newObj = data.map((item, index) => {
      return {
        id: `itemId${index}`,
        content: getRow(item, index),
        rowData: item,
      };
    });
    setListItem(newObj);
    setInfoModel(data);
    changeLoading(false);
  }
  async function saveData() {
    changeLoading(true);
    const { error, data } = await requestAxios({
      url: `DishTypes/${
        currentRow.Id !== 0 ? "UDishType" : "CDishType"
      }?menuCode=${id}`,
      method: currentRow.Id !== 0 ? "put" : "post",
      token: infoUs.bearer_token,
      data: currentRow,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }
  async function saveOrderData() {
    changeLoading(true);

    const postObj = listItem.map((item) => {
      return {
        Id: item.rowData.Id,
        newOrder: item.rowData.Order,
        menuCode: id,
      };
    });
    console.log(listItem);
    const { error, data } = await requestAxios({
      url: `DishTypes/UpdateOrderCategory?menuCode=${id}`,
      method: "put",
      token: infoUs.bearer_token,
      data: postObj,
    });
    if (error === "") {
      changeLoading(false);
      const MySwal = withReactContent(Swal);
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: <i>Saved success!</i>,
        icon: "success",
      });
      loadInfo();
      handleClose();
    } else {
      alert("Error, try again");
    }
    changeLoading(false);
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      saveData();
    }
  };

  function getRow(dataRow, index) {
    return (
      <div className="rowDragDropCategories__container">
        <div>
          {/* <i class="fa fa-arrows" aria-hidden="true"></i>
          {dataRow.Order}  */}
        </div>
        <div>{dataRow.Name}</div>
        <div>
          <i
            class={`fa fa-check-circle-o ActiveDataTable__Menu ${
              !dataRow.Active && "ActiveDataTable__MenuInactive"
            }`}
            aria-hidden="true"
          />
        </div>
        <div className="">
          <button
            className="btnEditMenu btnRowDataTable"
            onClick={() => {
              handleShow();
              setCurrentRow(dataRow);
            }}
          >
            <img className="imgEditMenu" src={EdithDishestype} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <HeaderDashboard />
      <div className="box-container ">
        <h1>
          Categories
          <button
            className="btnEnter_MenuNav"
            onClick={() => {
              handleShow();
              setCurrentRow({ Id: 0, RestaurantId: id, Active: 1 });
            }}
          >
            <img
              className="iconEnter_MenuNav btnRowDataTable"
              src={AddDishestype}
            />
          </button>
        </h1>
        <div className="btnHeaderContainer__categories">
          <button
            className="btnSaveOrder__Categories"
            onClick={() => {
              saveOrderData();
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />
            &nbsp;&nbsp; Save Order
          </button>
        </div>
        <div className="rowDragDropCategories__container rowDragDropCategories__title">
          <div></div>
          <div>Name</div>
          <div className="">Active</div>
          <div />
        </div>
        <DragDrop listItem={listItem} setListItem={setListItem} />
        <div className="Restaurant__container">
          <Modal show={show} onHide={handleClose} className="ModalEditRow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{currentRow.Name} Information</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="comboBox comboBoxLocation__Categories">
                  Location
                  <Form.Select
                    value={currentRow.Type}
                    onChange={(input) =>
                      setNewValue("Type", input.target.value)
                    }
                  >
                    <option value="1"> Restaurant</option>
                    <option value="2"> Bar</option>
                  </Form.Select>
                </div>
                <InputForm
                  title="CATEGORY'S NAME"
                  field="Name"
                  infoModel={infoModel}
                  Row={currentRow}
                  setNewValue={setNewValue}
                  required
                />
                Active
                <br />
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  variant={"outline-success"}
                  name="radio"
                  value={1}
                  checked={1 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 1)}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  id={`radio-1`}
                  type="radio"
                  name="radio"
                  value={0}
                  variant={"outline-danger"}
                  checked={0 === currentRow.Active}
                  onClick={(input) => setNewValue("Active", 0)}
                >
                  Inactive
                </ToggleButton>
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Categories;
